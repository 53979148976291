import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Input,
  InputOnChangeData,
  Label,
  makeStyles,
  tokens,
} from '@fluentui/react-components'
import { ChangeEvent, FormEvent, useState } from 'react'

const useStyles = makeStyles({
  dialogBody: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalM,
  },
  dialogActions: {
    justifyContent: 'end',
  },
})

type CreateOrganizationDialogProps = {
  open: boolean
  onOpenChange: (open: boolean) => void
  onCreateOrganization: (organizationName: string) => void
}

export function CreateOrganizationDialog({
  open,
  onOpenChange,
  onCreateOrganization,
}: CreateOrganizationDialogProps) {
  const styles = useStyles()

  const [organizationName, setOrganizationName] = useState('')

  return (
    <Dialog
      open={open}
      onOpenChange={(_event, data) => onOpenChange(data.open)}
    >
      <DialogSurface>
        <form onSubmit={handleSubmit}>
          <DialogBody className={styles.dialogBody}>
            <DialogTitle>Create organization</DialogTitle>
            <DialogContent className={styles.dialogContent}>
              <Label htmlFor={'organization-input'}>Organization name</Label>
              <Input
                id="organization-input"
                onChange={handleChange}
                value={organizationName}
              />
            </DialogContent>
            <DialogActions className={styles.dialogActions}>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">Cancel</Button>
              </DialogTrigger>
              <DialogTrigger disableButtonEnhancement>
                <Button
                  type="submit"
                  appearance="primary"
                  disabled={!organizationName}
                >
                  Create
                </Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  )

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    onCreateOrganization(organizationName)
    setOrganizationName('')
  }

  function handleChange(
    _event: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) {
    setOrganizationName(data.value)
  }
}
