import { useCallback, useEffect, useState } from 'react'
import { Client } from 'urql'

import {
  OrganizationsDocument,
  OrganizationsQuery,
} from '../graphql/__generated__/graphql'

export type Organization = {
  id: string
  name: string
}

type usePollOrganizationsProps = {
  client?: Client
  paused: boolean
  pollInterval: number
}

function usePollOrganizations({
  client,
  paused,
  pollInterval,
}: usePollOrganizationsProps): [Organization[] | undefined, () => void] {
  const [organizations, setOrganizations] = useState<
    Organization[] | undefined
  >()

  const getOrganizations = useCallback(() => {
    if (!client) {
      return
    }

    client
      .query<OrganizationsQuery>(
        OrganizationsDocument,
        {},
        { requestPolicy: 'network-only' }
      )
      .toPromise()
      .then((result) => {
        if (result.error) {
          console.error(result.error.message)
        } else {
          const organizationsData =
            result?.data?.organizations?.organizations ?? []
          const organizations = organizationsData.map((organization) => {
            // TODO Use ARNs throughout the application
            const id = organization.arn.split(':').at(-1)
            if (!id) {
              throw new Error('Invalid ARN from ACX API')
            }
            return {
              id,
              name: organization.name,
            }
          })

          setOrganizations((previousOrganizations) => {
            if (previousOrganizations?.length !== organizations.length) {
              return organizations
            }

            const organizationIds = organizations
              .map((organization) => organization.id)
              .join(',')
            const previousOrganizationIds = previousOrganizations
              .map((organization) => organization.id)
              .join(',')
            if (organizationIds !== previousOrganizationIds) {
              return organizations
            }

            return previousOrganizations
          })
        }
      })
  }, [client])

  useEffect(() => {
    getOrganizations()
    const interval = setInterval(() => {
      if (paused) {
        return
      }
      getOrganizations()
    }, pollInterval)
    return () => clearInterval(interval)
  }, [getOrganizations, paused, pollInterval])

  return [organizations, getOrganizations]
}

export { usePollOrganizations }
