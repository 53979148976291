import { axisDarkTheme } from '@axiscommunications/fluent-theme'
import { DiscoveryProvider } from '@axteams-one/bws-cloud-discovery/react'
import { FluentProvider } from '@fluentui/react-components'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { AlertProvider } from './components/AlertProvider'
import { LoadingPage } from './components/Loading'
import { config, getEnvironment, setConfig } from './config'
import { ClaimSystemPage } from './pages/ClaimSystemPage'
import { InternalErrorPage } from './pages/InternalErrorPage'
import { AuthenticatedRoute } from './util/AuthenticatedRoute'

showConsoleMotd()
main()

function main() {
  setConfig({ environment: getEnvironment(window.location.hostname) })

  // TODO We've removed StrictMode because Fluent UI currently doesn't support
  // StrictMode in React 18. As soon as it's supported we should add it back in
  // all applications.
  const container = document.getElementById('root') as HTMLElement
  const root = createRoot(container)
  root.render(
    <FluentProvider theme={axisDarkTheme}>
      <DiscoveryProvider
        endpoint={config.discoveryApiUrl}
        errorPlaceholder={<InternalErrorPage />}
        loadingPlaceholder={<LoadingPage />}
      >
        <AlertProvider>
          <BrowserRouter>
            <Routes>
              <Route
                path="setup"
                element={
                  <AuthenticatedRoute>
                    <ClaimSystemPage />
                  </AuthenticatedRoute>
                }
              />
            </Routes>
          </BrowserRouter>
        </AlertProvider>
      </DiscoveryProvider>
    </FluentProvider>
  )
}

function showConsoleMotd() {
  // eslint-disable-next-line no-console
  console.log(`%cWarning`, 'color:red;font-size:2rem')
  // eslint-disable-next-line no-console
  console.log(`This is a browser feature meant for developers.
If you have been told to copy and paste something here you may be the target of fraud.
For more information please refer to https://en.wikipedia.org/wiki/Self-XSS.

For security-related inquiries, please refer to https://www.axis.com/security.txt.`)

  // eslint-disable-next-line no-console
  console.log(
    `Application version: ${import.meta.env['VITE_APP_VERSION_LONG'] || 'unknown'}.`
  )
}
