import {
  Body1,
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemRadio,
  MenuList,
  MenuOpenChangeData,
  MenuOpenEvent,
  MenuPopover,
  MenuTrigger,
  Skeleton,
  SkeletonItem,
  Tooltip,
  makeStyles,
  tokens,
  useRestoreFocusTarget,
} from '@fluentui/react-components'
import {
  Add24Regular,
  BuildingMultiple24Regular,
  Checkmark20Regular,
  Info16Regular,
  Warning20Filled,
} from '@fluentui/react-icons'
import { Dispatch, SetStateAction, useState } from 'react'

import { CreateOrganizationDialog } from './CreateOrganizationDialog'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  skeleton: {
    width: '400px',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    columnGap: tokens.spacingHorizontalS,
  },
  dialogTriggerButton: {
    width: '400px',
  },
  dialogTriggerButtonText: {
    width: '100%',
    justifySelf: 'start',
  },
  menuButton: {
    width: '400px',
  },
  menuButtonText: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
  menuPopover: {
    maxHeight: '320px',
    width: '400px',
    minWidth: '400px',
    overflowY: 'auto',
    zIndex: 100,
  },
  menuItem: {
    width: '400px',
    minWidth: '100%',
  },
  icon: {
    flexShrink: 0,
  },
})

export type Organization = {
  id: string
  name: string
}

type OrganizationStepProps = {
  loading: boolean
  onCreateOrganization: (organizationName: string) => void
  onSetOrganization: (organization: Organization) => void
  organization?: Organization
  organizations?: Organization[]
  setPauseOrganizationPolling: Dispatch<SetStateAction<boolean>>
  showWarning: boolean
}

function OrganizationStep({
  loading,
  onCreateOrganization,
  onSetOrganization,
  organization,
  organizations,
  setPauseOrganizationPolling,
  showWarning,
}: OrganizationStepProps) {
  const styles = useStyles()
  const restoreFocusTargetAttribute = useRestoreFocusTarget()
  const [menuOpen, setMenuOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)

  if (loading) {
    return (
      <div className={styles.container}>
        <OrganizationHeading />
        <Skeleton
          className={styles.skeleton}
          aria-label="Loading organizations"
        >
          <SkeletonItem size={40} appearance="translucent" />
        </Skeleton>
      </div>
    )
  }

  if (organizations && organizations.length === 0) {
    return (
      <div className={styles.container}>
        <OrganizationHeading />
        <Button
          className={styles.dialogTriggerButton}
          appearance="secondary"
          size="large"
          icon={<Add24Regular color={tokens.colorNeutralStrokeAccessible} />}
          onClick={() => setDialogOpen(true)}
        >
          <Body1 className={styles.dialogTriggerButtonText}>
            Create new organization
          </Body1>
        </Button>
        <CreateOrganizationDialog
          open={dialogOpen}
          onOpenChange={setDialogOpen}
          onCreateOrganization={onCreateOrganization}
        />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <OrganizationHeading />
      <div className={styles.content}>
        <OrganizationMenu />
        <CreateOrganizationDialog
          open={dialogOpen}
          onOpenChange={setDialogOpen}
          onCreateOrganization={onCreateOrganization}
        />
        {organization && <OrganizationStatusIcon showWarning={showWarning} />}
      </div>
    </div>
  )

  function OrganizationMenu() {
    const styles = useStyles()
    const organizationMenuOptions = organizations?.map(menuItemMapper)

    return (
      <Menu
        inline
        positioning={'below-end'}
        open={menuOpen}
        onOpenChange={onOpenChange}
        checkedValues={{ organization: [organization?.id ?? ''] }}
      >
        <MenuTrigger>
          <MenuButton
            className={styles.menuButton}
            appearance="secondary"
            size="large"
            icon={
              <BuildingMultiple24Regular
                color={tokens.colorNeutralStrokeAccessible}
              />
            }
          >
            <Body1 className={styles.menuButtonText}>
              {organization?.name ?? 'Select organization'}
            </Body1>
          </MenuButton>
        </MenuTrigger>
        <MenuPopover className={styles.menuPopover}>
          <MenuList>
            {organizationMenuOptions}
            <MenuDivider />
            <MenuItem
              className={styles.menuItem}
              {...restoreFocusTargetAttribute}
              icon={<Add24Regular />}
              onClick={() => setDialogOpen(true)}
            >
              {'Create new organization'}
            </MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
    )
  }

  function menuItemMapper(organization: Organization) {
    return (
      <MenuItemRadio
        className={styles.menuItem}
        key={organization.id}
        name="organization"
        value={organization.id}
        onClick={() =>
          onSetOrganization({
            id: organization.id,
            name: organization.name,
          })
        }
      >
        {organization.name}
      </MenuItemRadio>
    )
  }

  function onOpenChange(_event: MenuOpenEvent, data: MenuOpenChangeData) {
    setMenuOpen(data.open)
    setPauseOrganizationPolling(!data.open)
  }
}

function OrganizationHeading() {
  return (
    <h4>
      Organization
      <Tooltip
        content={
          'This is the organization your body worn system will be connected to. ' +
          'Only users in this organization can have access to view streams.'
        }
        relationship="label"
      >
        <Button appearance="transparent" icon={<Info16Regular />} />
      </Tooltip>
    </h4>
  )
}

function OrganizationStatusIcon({ showWarning }: { showWarning: boolean }) {
  if (showWarning) {
    return <Warning20Filled color={tokens.colorStatusWarningForeground3} />
  }

  return <Checkmark20Regular color={tokens.colorBrandForeground1} />
}

export { OrganizationStep }
