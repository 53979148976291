import { Input, Text, makeStyles, tokens } from '@fluentui/react-components'
import { Checkmark20Regular } from '@fluentui/react-icons'
import { ClipboardEvent, FormEvent } from 'react'

import { AUTH_CODE_LENGTH } from '../../constants'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  header: {
    marginBottom: '0.5em',
  },
  description: {
    marginBottom: '0.5em',
    maxWidth: '400px',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    columnGap: tokens.spacingHorizontalS,
  },
  authcode: {
    width: '400px',
    '& > input': {
      textAlign: 'left',
      paddingLeft: '8em',
      letterSpacing: tokens.spacingHorizontalL,
    },
  },
  checkmarkIcon: {
    flexShrink: 0,
    color: tokens.colorBrandForeground1,
  },
})

type AuthCodeStepProps = {
  authCode?: string
  onSetAuthCode: (orgId: string) => void
}

function AuthCodeStep({ authCode = '', onSetAuthCode }: AuthCodeStepProps) {
  const styles = useStyles()

  return (
    <div className={styles.container}>
      <h4 className={styles.header}>Authorization code</h4>
      <Text as="p" className={styles.description}>
        Go back to AXIS Body Worn Manager and copy the authorization code
      </Text>
      <div className={styles.content}>
        <Input
          className={styles.authcode}
          autoComplete="off"
          value={authCode}
          size="large"
          onChange={handleChange}
          onPaste={handlePaste}
          placeholder={'-'.repeat(AUTH_CODE_LENGTH)}
        />
        {authCode.length === AUTH_CODE_LENGTH && (
          <Checkmark20Regular className={styles.checkmarkIcon} />
        )}
      </div>
    </div>
  )

  function handleChange(event: FormEvent<HTMLInputElement>) {
    const input = event.currentTarget.value
    // Match 0-6 digits
    const regex = new RegExp(`^\\d{0,${AUTH_CODE_LENGTH}}$`)
    if (input.match(regex)) {
      onSetAuthCode(event.currentTarget.value)
    }
  }

  function handlePaste(event: ClipboardEvent<HTMLInputElement>) {
    // Remove all whitespace
    const authCode = event.clipboardData.getData('text').replace(/\s+/, '')
    event.currentTarget.value = authCode
    onSetAuthCode(authCode)

    // Stop the browser from pasting the unconverted text as well
    event.preventDefault()
  }
}

export { AuthCodeStep }
