import { useCallback, useEffect, useState } from 'react'
import { Client } from 'urql'

import {
  AllResourceGroupsDocument,
  AllResourceGroupsQuery,
  ErrorCode,
} from '../graphql/__generated__/graphql'
import { ApiResult, ApiStatus, InternalErrorCode } from './types'

export interface ResourceGroup {
  id: string
  arn: string
  name: string
  parent: string
}

type usePollResourceGroupsProps = {
  client?: Client
  organizationId?: string
  paused: boolean
  pollInterval: number
}

type usePollResourceGroupsResult = [
  ApiResult<ResourceGroup[]>,
  (organizationId: string) => void,
]

export function usePollResourceGroups({
  client,
  paused,
  pollInterval,
  organizationId,
}: usePollResourceGroupsProps): usePollResourceGroupsResult {
  const [result, setResult] = useState<ApiResult<ResourceGroup[]>>({
    status: ApiStatus.Idle,
  })

  const getResourceGroups = useCallback(
    (organizationId?: string) => {
      if (!client || !organizationId) {
        return
      }

      client
        .query<AllResourceGroupsQuery>(
          AllResourceGroupsDocument,
          {
            input: {
              arn: `arn:organization:${organizationId}`,
            },
          },
          { requestPolicy: 'network-only' }
        )
        .toPromise()
        .then((result) => {
          if (result.error) {
            const errorCode = result.error.graphQLErrors[0].extensions.code
            console.error(result.error.message)

            setResult({
              status: ApiStatus.Rejected,
              error: errorCode as ErrorCode,
            })
          } else {
            const resourceGroups = result.data?.allChildren?.children
            if (!resourceGroups) {
              return
            }
            const data = resourceGroups.map((resourceGroup) => {
              return {
                id: resourceGroup.id,
                arn: `arn:resource-group:${organizationId}/${resourceGroup.id}`,
                name: resourceGroup.name,
                parent: resourceGroup.parent,
              }
            })
            setResult({
              status: ApiStatus.Resolved,
              data,
            })
          }
        })
        .catch(() => {
          setResult({
            status: ApiStatus.Rejected,
            error: InternalErrorCode.NetworkError,
          })
        })
    },
    [client]
  )

  useEffect(() => {
    getResourceGroups(organizationId)
    const interval = setInterval(() => {
      if (paused) {
        return
      }
      getResourceGroups(organizationId)
    }, pollInterval)
    return () => clearInterval(interval)
  }, [getResourceGroups, organizationId, paused, pollInterval])

  return [result, getResourceGroups]
}
