import { StorageLocation } from '@axteams-one/bws-cloud-discovery'
import {
  Body1,
  Button,
  Menu,
  MenuButton,
  MenuItemRadio,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Tooltip,
  makeStyles,
  tokens,
} from '@fluentui/react-components'
import {
  Checkmark20Regular,
  GlobeLocation24Regular,
  Info16Regular,
} from '@fluentui/react-icons'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: tokens.spacingVerticalXXXL,
    marginBottom: tokens.spacingVerticalXXL,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    columnGap: tokens.spacingHorizontalS,
  },
  dialogTriggerButton: {
    width: 'fit-content',
    maxWidth: '400px',
  },
  dialogTriggerButtonText: {
    width: '100%',
    justifySelf: 'start',
  },
  menuButton: {
    width: '400px',
  },
  menuButtonText: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
  menuPopover: {
    maxHeight: '260px',
    width: '400px',
    minWidth: '400px',
    overflowY: 'auto',
    zIndex: 100,
  },
  menuItem: {
    width: '100%',
    maxWidth: '100%',
  },
  checkmarkIcon: {
    flexShrink: 0,
    color: tokens.colorBrandForeground1,
  },
})

type StorageLocationStepProps = {
  onSetStorageLocation: (storageLocation: StorageLocation) => void
  storageLocation: StorageLocation | undefined
  storageLocations: StorageLocation[]
}

function StorageLocationStep({
  onSetStorageLocation,
  storageLocation,
  storageLocations,
}: StorageLocationStepProps) {
  const styles = useStyles()
  const storageLocationMenuOptions = storageLocations.map(menuItemMapper)

  const tooltipContent =
    'The storage location is where streamed evidence data will be stored.'

  const icon = (
    <GlobeLocation24Regular color={tokens.colorNeutralStrokeAccessible} />
  )

  const selectedStorageLocationText =
    storageLocation?.name || 'Select a storage location'

  return (
    <div className={styles.container}>
      <h4>
        Storage location
        <Tooltip content={tooltipContent} relationship="label">
          <Button appearance="transparent" icon={<Info16Regular />} />
        </Tooltip>
      </h4>
      <div className={styles.content}>
        <Menu
          inline
          positioning={'below-end'}
          checkedValues={{ storageLocation: [storageLocation?.id || ''] }}
        >
          <MenuTrigger>
            <MenuButton
              className={styles.menuButton}
              appearance="secondary"
              size="large"
              icon={icon}
            >
              <Body1 className={styles.menuButtonText}>
                {selectedStorageLocationText}
              </Body1>
            </MenuButton>
          </MenuTrigger>
          <MenuPopover className={styles.menuPopover}>
            <MenuList>{storageLocationMenuOptions}</MenuList>
          </MenuPopover>
        </Menu>
        {storageLocation && (
          <Checkmark20Regular className={styles.checkmarkIcon} />
        )}
      </div>
    </div>
  )

  function menuItemMapper(storageLocation: StorageLocation) {
    return (
      <MenuItemRadio
        className={styles.menuItem}
        key={storageLocation.id}
        name="storageLocation"
        value={storageLocation.id}
        onClick={() => onSetStorageLocation(storageLocation)}
      >
        {storageLocation.name}
      </MenuItemRadio>
    )
  }
}

export { StorageLocationStep }
